import { Heading, Box, Text, SimpleGrid, Button } from "@chakra-ui/react";
import { Fragment } from "react";

export function NextEditionCTA() {
  return (
    <Fragment>
    <Box className="card-more-info" borderRadius="6" boxShadow={"xl"}>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        gap="15px"
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
        marginTop={{ base: "10%", md: "5%" }}
      >
        <Heading
          className="information-heading"
          marginBottom={{ base: "1%", md: "1%" }}
        >
           Reserva tu plaza y deja de contratar como en 2010!
        </Heading>
        <Text>
          Déjame tus datos y te mandaré todos los detalles y el dossier de la
          formación. <b>Deja de contratar como en 2010</b> y descubre los
          secretos para contratar talento tecnológico sin complicaciones.
        </Text>
        <Text>
          <b>👉 Próxima edición: 8 y 9 de febrero 2025</b>
        </Text>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap="15px"
          marginTop={{ base: "1%", md: "1%" }}
          marginBottom={{ base: "10%", md: "5%" }}
        >
         <Button onClick={window['show_Join_Popup']}>
              Reservar mi plaza
            </Button>
            <Button colorScheme="pink" onClick={window['show_MoreInformation_Popup']}>
              Quiero más información
            </Button>
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  </Fragment>
);
}
