import { Fragment } from "react";
import { Heading } from "@chakra-ui/react";

export function Blog() {
  return (
    <Fragment>
     <Heading
             className="hero-heading"
             marginLeft={{ base: "5%", md: "20%" }}
             marginRight={{ base: "5%", md: "20%" }}
             marginTop={{ base: "5vh", md: "10vh" }}
             marginBottom={{ base: "0vh", md: "5vh" }}
             fontSize={{ base: "9vh", md: "10vh" }}
           >
             Qué problemática busca resolver el taller<span style={{ color: "#66C7F4" }}>?</span>
           </Heading>
    </Fragment>
  );
}
