import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
  Box,
} from "@chakra-ui/react";

export function AcademyValue() {
  return (
    <Box marginTop={{ base: "25vw", md: "5vw" }}>
      &nbsp;
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Qué te llevarás de la formación
        <span style={{ color: "#66C7F4" }}>?</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
      >
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"400px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Transforma tu proceso de selección: Aumenta tu tasa de éxito.
                </Heading>
                <Text>
                  Aprenderás a entender a fondo el perfil de cada posición y a
                  alinear a tu equipo de selección con el hiring manager y el
                  equipo técnico. Descubrirás cómo comprender las necesidades y
                  aspiraciones de los candidatos. Con estas habilidades, no solo
                  podrás entrevistar a menos personas, ¡sino a las adecuadas! <b>Es
                  mejor conocer a 3 candidatos excepcionales que a 10 que no
                  encajan.</b>
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"400px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Reduce tiempos y acelera resultados.
                </Heading>
                <Text>
                  Sabrás exactamente dónde encontrar a los candidatos ideales:
                  en qué comunidades, eventos y plataformas buscar. Además,
                  aprenderás a automatizar procesos para que tu reclutamiento
                  sea más ágil y eficaz. <b>¡Menos tiempo buscando y más tiempo
                  seleccionando al talento que tu equipo necesita!</b>
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"400px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Mide, itera y mejora.
                </Heading>
                <Text>
                  Aprenderás a monitorear y optimizar tus procesos de selección
                  constantemente. Con datos en la mano, podrás identificar áreas
                  de mejora y hacer ajustes en tiempo real, llevando tu proceso
                  de reclutamiento a un nivel de excelencia.
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"400px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Ahorra dinero: Maximiza oportunidades y minimiza costes.
                </Heading>
                <Text>
                  El tiempo es un recurso valioso. Al agilizar tu proceso de
                  contratación, ahorrarás dinero y tiempo. Pero... <b>¿te has
                  planteado el verdadero coste de oportunidad de no contratar a
                  la persona adecuada en 3 o 4 meses?</b> Te mostraré cómo evitar
                  esas pérdidas y hacer que cada día cuente.
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
