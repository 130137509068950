import {
  Heading,
  Card,
  Text,
  CardBody,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Fragment } from "react";

export function IndividualFormat() {
  return (
    <Fragment>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="15px">

      <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack  spacing="3">
              <Heading size="md" className="benefits-heading">
              Próxima edición
              </Heading>
              <Text>8 y 9 de febrero de 2025</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack  spacing="3">
              <Heading size="md" className="benefits-heading">
                Plazas limitadas
              </Heading>
              <Text>Sacarás el máximo provecho del curso. <b>Mínimo 10 y máximo 15</b> asistentes por edición.</Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack spacing="3">
              <Heading size="md" className="benefits-heading">
                Duración
              </Heading>
              <Text>
                10 horas distribuidas en 2 días (5 horas el sábado y 5 horas el
                domingo).
              </Text>
            </Stack>
          </CardBody>
        </Card>

        <Card
          borderRadius="6"
          boxShadow={"xl"}
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <CardBody>
            <Stack  spacing="3">
              <Heading size="md" className="benefits-heading">
                450 €
              </Heading>
              
              <Text>Las primeras 5 plazas de cada edición rebajadas a 300€.</Text>
            </Stack>
          </CardBody>
        </Card>

        
      </SimpleGrid>
    </Fragment>
  );
}
