import {
  Heading,
  Box,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
  Text,
} from "@chakra-ui/react";

import { ACADEMY_CONTENT } from "../../configuration/academy-content";
import { Fragment } from "react";
import { NextEditionCTA } from "./academy-next-edition-cta";

export function AcademyContent() {
  return (
    <Fragment>
      <Box
        className="my-history-box"
        marginTop={{ base: "0vw", md: "1vw" }}
        marginBottom={{ base: "20vh", md: "15vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Heading
          className="what-i-do-heading"
          marginBottom={{ base: "15vw", md: "5vw" }}
          fontSize={{ base: "8vh", md: "12vh" }}
          lineHeight={{ base: "80%", md: "100%" }}
        >
          Contenido de la formación<span style={{ color: "#f10965" }}>:</span>
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 8, md: 10 }}>
          {ACADEMY_CONTENT.map((post) => (
            <Stack spacing={4}>
              <Card
                borderRadius="6"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                minH={"250px"}
              >
                <Stack>
                  <CardBody minHeight={"270px"}>
                    <Heading
                      size="md"
                      className="benefits-heading"
                      color={post.color_title}
                      marginBottom={{ base: "1vh", md: "2vh" }}
                    >
                      {post.title}
                    </Heading>
                    <Text>{post.description}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </Stack>
          ))}
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 1 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "5%", md: "5%" }}
        >
          <NextEditionCTA />
        </SimpleGrid>
      </Box>
    </Fragment>
  );
}
