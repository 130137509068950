import {
  Heading,
  Box,
  Text,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { MoreInformation } from "./academy-more-information";
import { IndividualFormat } from "./academy-individual-format";

export function AcademyFormats() {
  return (
    <Box className="my-history-box">
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        El formato<span style={{ color: "#C9E500" }}>.</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "5vh", md: "10vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text>
            Es un taller pensado y diseñado para ser muy práctico y del que los
            alumnos se llevarán acciones que puedan poner en práctica desde el
            día siguiente. Habrá dos sesiones con invitados; una en la que
            hablaremos con candidatos/as y contarán que red flags identifican en
            una oferta de empleo, como les gusta que sea el proceso completo… y
            otra sesión en la que personas que han contratado mucho y creado
            equipos para sus empresa nos contaran errores cometidos, buenas
            prácticas…
          </Text>
        </Stack>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vh", md: "27vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <IndividualFormat />

        <MoreInformation />
      </SimpleGrid>
    </Box>
  );
}
